import { authModeParams, buildGraphQLVariables, flattenItems, generateGraphQLDocument, getCustomHeaders, initializeModel, } from '../APIClient';
export function indexQueryFactory(client, modelIntrospection, model, indexMeta, context = false) {
    const indexQueryWithContext = async (contextSpec, args, options) => {
        return _indexQuery(client, modelIntrospection, model, indexMeta, {
            ...args,
            ...options,
        }, contextSpec);
    };
    const indexQuery = async (args, options) => {
        return _indexQuery(client, modelIntrospection, model, indexMeta, {
            ...args,
            ...options,
        });
    };
    return context ? indexQueryWithContext : indexQuery;
}
function processGraphQlResponse(result, selectionSet, modelInitializer) {
    const { data, extensions } = result;
    const [key] = Object.keys(data);
    if (data[key].items) {
        const flattenedResult = flattenItems(data)[key];
        return {
            data: selectionSet ? flattenedResult : modelInitializer(flattenedResult),
            nextToken: data[key].nextToken,
            extensions,
        };
    }
    return {
        data: data[key],
        nextToken: data[key].nextToken,
        extensions,
    };
}
function handleGraphQlError(error) {
    if (error.errors) {
        // graphql errors pass through
        return error;
    }
    else {
        // non-graphql errors re re-thrown
        throw error;
    }
}
async function _indexQuery(client, modelIntrospection, model, indexMeta, args, contextSpec) {
    const { name } = model;
    const query = generateGraphQLDocument(modelIntrospection, name, 'INDEX_QUERY', args, indexMeta);
    const variables = buildGraphQLVariables(model, 'INDEX_QUERY', args, modelIntrospection, indexMeta);
    const auth = authModeParams(client, args);
    const modelInitializer = (flattenedResult) => initializeModel(client, name, flattenedResult, modelIntrospection, auth.authMode, auth.authToken, !!contextSpec);
    try {
        const headers = getCustomHeaders(client, args?.headers);
        const graphQlParams = {
            ...auth,
            query,
            variables,
        };
        const requestArgs = [graphQlParams, headers];
        if (contextSpec !== undefined) {
            requestArgs.unshift(contextSpec);
        }
        const response = (await client.graphql(...requestArgs));
        if (response.data !== undefined) {
            return processGraphQlResponse(response, args?.selectionSet, modelInitializer);
        }
    }
    catch (error) {
        return handleGraphQlError(error);
    }
}
